<template>
  <Editor v-model="data.text" class="py-1.5" @updateBlock="$emit('updateBlock', data.text)"/>
</template>

<script>
import Editor from '@/components/utils/Editor.vue'

export default {
  props: ['data'],
  components: {
    Editor
  },
  updated () { }
}
</script>
