<template>
  <div class="single-block group w-100 position-relative">
    <div class="d-flex position-absolute tooltip-group">
      <Tooltip value="<span class='text-neutral-400'><span class='text-white'>Click</span> to add block below</span>">
        <i @click="$emit('deleteBlock')" class="bi bi-trash"></i>
      </Tooltip>
      <Tooltip value="<span class='text-neutral-400'><span class='text-white'>Click</span> to add block below</span>">
        <i @click="$emit('newBlock')" class="bi bi-plus"></i>
      </Tooltip>
      <Tooltip value="<span class='text-neutral-400'><span class='text-white'>Drag</span> to move<br/><span class='text-white'>Click</span> to open menu</span>">
        <i @mouseup="$event.stopPropagation()" class="bi bi-grip-vertical"></i>
      </Tooltip>
    </div>
    <component :is="BlockComponents[type]" :data="data" @updateBlock="updateBlock" ref="content" @keydown.native="keyDownHandler" />
  </div>
</template>

<script>
import Tooltip from './utils/Tooltip.vue'
import { BlockComponents } from './utils/BlockTypes'
import { BlockType, isTextBlock } from '@/utils/block.type'

export default {
  data () {
    return {
      updateTimeout: null,
      cursorPosition: null,
      BlockComponents
    }
  },
  props: ['id', 'data', 'type', 'numerator'],
  components: {
    Tooltip
  },
  mounted () { },
  updated () { },
  methods: {
    setCursorPosition (position) {
      this.cursorPosition = position
    },
    updateBlock (text) {
      clearTimeout(this.updateTimeout)
      const that = this
      this.updateTimeout = setTimeout(function () {
        that.$emit('updateBlock', that.$props.numerator, text)
      }, 700)
    },
    getCaretCoordinates () {
      const x = 0
      const y = 0
      const selection = window.getSelection()
      if (selection?.rangeCount > 0) {
        const range = selection?.getRangeAt(0)
        if (range?.startContainer.firstChild) {
          const newRange = document.createRange()
          newRange.selectNodeContents(range.startContainer.firstChild)
          newRange.collapse(true)
          const rect = newRange.getBoundingClientRect()
          return rect
        }
        const rect = range?.getBoundingClientRect()
        return rect
      }
      return { x, y }
    },
    getLastChild () {
      if (isTextBlock(this.$props.type)) {
        if (this.$refs.content.$el.firstChild.firstChild.childNodes.length > 1) {
          return this.$refs.content.$el.firstChild.firstChild.lastChild
        } else {
          return this.$refs.content.$el.firstChild.firstChild.firstChild
        }
      } else {
        if (this.$refs.content.$el) return this.$refs.content.$el.firstChild || this.$refs.content.value.$el
        else return this.$refs.content.firstChild || this.$refs.content.value
      }
    },
    getStartCoordinates () {
      let x = 0
      let y = 0
      const firstChild = this.getFirstChild()
      if (firstChild) {
        const range = document.createRange()
        range.selectNodeContents(firstChild.firstChild || firstChild)
        range.collapse(true)
        const rect = range.getBoundingClientRect()
        x = rect.left
        y = rect.top
      }
      return { x, y }
    },
    getEndCoordinates () {
      let x = 0
      let y = 0
      const lastChild = this.getLastChild()
      if (lastChild) {
        const range = document.createRange()
        range.selectNodeContents(lastChild.firstChild || lastChild)
        range.collapse()
        const rect = range.getBoundingClientRect()
        x = rect.left
        y = rect.top
      }
      return { x, y }
    },
    atFirstLine () {
      const startCoord = this.getStartCoordinates()
      const coord = this.getCaretCoordinates()
      return coord?.y === startCoord.y
    },
    atLastLine () {
      const endCoord = this.getEndCoordinates()
      const coord = this.getCaretCoordinates()
      return coord?.y === endCoord.y
    },
    keyDownHandler (event) {
      if (event.key === 'ArrowUp') {
        if (this.atFirstLine()) {
          event.preventDefault()
          this.$emit('moveToPrevLine')
        }
      } else if (event.key === 'ArrowDown') {
        if (this.atLastLine()) {
          event.preventDefault()
          this.$emit('moveToNextLine')
        }
      } else if (event.key === 'Enter') {
        event.preventDefault()
        this.$emit('split')
      } else if (event.key === 'Backspace') {
        const selection = window.getSelection()
        if (selection && selection.anchorOffset === 0) {
          event.preventDefault()
          this.$emit('merge')
        }
      }
    },
    parseMarkdown (event) {
      console.log(event)
    },
    isContentBlock () {
      return [BlockType.Text, BlockType.Quote, BlockType.H1, BlockType.H2, BlockType.H3].includes(this.$props.type)
    },
    getFirstChild () {
      if (isTextBlock(this.$props.type)) {
        if (this.$refs.content.$el.firstChild.firstChild.childNodes.length > 1) {
          return this.$refs.content.$el.firstChild.firstChild.firstChild
        } else {
          return this.$refs.content.$el.firstChild.firstChild.firstChild
        }
      } else {
        if (this.$refs.content.$el) return this.$refs.content.$el.firstChild || this.$refs.content.$el
        else return this.$refs.content.firstChild || this.$refs.content
      }
    },
    moveToStart () {
      if (this.isContentBlock()) {
        const firstChild = this.getFirstChild()
        if (firstChild) {
          const selection = window.getSelection()
          const range = document.createRange()
          range.selectNodeContents(firstChild)
          range.collapse(true)
          // eslint-disable-next-line no-unused-expressions
          selection?.removeAllRanges()
          // eslint-disable-next-line no-unused-expressions
          selection?.addRange(range)
        }
      } else {
        this.$emit('moveToNextChar')
      }
    },
    getInnerContent () {
      if (isTextBlock(this.$props.type)) {
        return this.$refs.content.$el.firstChild.firstChild.firstChild
      } else {
        return this.$refs.content.$el.firstChild
      }
    },
    getTextContent () {
      const innerContent = this.getInnerContent()
      if (innerContent) return innerContent.parentElement ? innerContent.parentElement.textContent : innerContent.textContent
      else return ''
    },
    getHtmlContent () {
      const innerContent = this.getInnerContent()
      if (innerContent) return innerContent.parentElement.innerHTML
      else return ''
    },
    setCaretPos (caretPos) {
      const innerContent = this.getInnerContent()
      if (innerContent) {
        if (isTextBlock(this.$props.type)) {
          let offsetNode
          let offset = 0
          const numNodes = this.$refs.content.$el.firstChild.firstChild.childNodes.length
          for (const [i, node] of this.$refs.content.$el.firstChild.firstChild.childNodes.entries()) {
            if (offset + node.textContent.length > caretPos || i === numNodes - 1) {
              offsetNode = node
              break
            }
            offset += node.textContent.length
            offsetNode = node
          }
          const selection = window.getSelection()
          const range = document.createRange()
          range.setStart(offsetNode.firstChild || offsetNode, caretPos - offset)
          range.setEnd(offsetNode.firstChild || offsetNode, caretPos - offset)
          // eslint-disable-next-line no-unused-expressions
          selection?.removeAllRanges()
          // eslint-disable-next-line no-unused-expressions
          selection?.addRange(range)
        } else {
          const selection = window.getSelection()
          const range = document.createRange()
          range.setStart(innerContent, caretPos)
          range.setEnd(innerContent, caretPos)
          // eslint-disable-next-line no-unused-expressions
          selection?.removeAllRanges()
          // eslint-disable-next-line no-unused-expressions
          selection?.addRange(range)
        }
      }
    },
    getCaretPos () {
      const selection = window.getSelection()
      if (selection) {
        if (isTextBlock(this.$props.type)) {
          // let offsetNode = 0
          let offset = 0
          let tag = null
          let selectedNode = selection.anchorNode
          if (['STRONG', 'EM'].includes(selectedNode?.parentElement?.tagName)) {
            selectedNode = selectedNode?.parentElement
            tag = selectedNode.tagName.toLowerCase()
          }
          // Edge case when character length is 1
          if (selectedNode !== null && selectedNode.childNodes.length > 0) {
            if (selectedNode.childNodes[0].textContent && selectedNode.childNodes[0].textContent.length <= 1) {
              selectedNode = selectedNode.childNodes[0]
            }
          }
          // eslint-disable-next-line no-unused-vars
          for (const [i, node] of this.$refs.content.$el.firstChild.firstChild.childNodes.entries()) {
            if (node === selectedNode) {
              // offsetNode = node
              if (node.tagName) offset += 2 + node.tagName.length
              break
            }
            if (node.tagName) offset += node.outerHTML.length
            else offset += node.textContent.length
            // offsetNode = node
          }
          return { pos: offset + selection.anchorOffset, tag }
        } else {
          return { pos: selection.anchorOffset }
        }
      } else {
        return { pos: 0 }
      }
    },
    moveToFirstLine () {
      if (this.isContentBlock()) {
        const textContent = this.getTextContent()
        if (!textContent) {
          this.moveToStart()
        } else {
          const prevCoord = this.getCaretCoordinates()
          let prevDist = 99999
          let caretPos = 1
          while (true) {
            this.setCaretPos(caretPos)
            const newCoord = this.getCaretCoordinates()
            const newDist = Math.abs(newCoord?.x - prevCoord?.x)
            if (newDist > prevDist) {
              if (caretPos > 0) this.setCaretPos(caretPos - 1)
              break
            } else if (caretPos === textContent.length || caretPos > 999) {
              // Reached end of line
              break
            } else {
              prevDist = newDist
              caretPos += 1
            }
          }
        }
      } else {
        this.$emit('moveToNextLine')
      }
    },
    moveToLastLine () {
      if (this.isContentBlock()) {
        const textContent = this.getTextContent()
        if (!textContent) {
          this.moveToStart()
        } else {
          const prevCoord = this.getCaretCoordinates()
          let prevDist = 99999
          let caretPos = textContent.length
          while (true) {
            this.setCaretPos(caretPos)
            const newCoord = this.getCaretCoordinates()
            const newDist = Math.abs(newCoord?.x - prevCoord?.x)
            if (newDist > prevDist) {
              if (caretPos < textContent.length) this.setCaretPos(caretPos + 1)
              break
            } else if (caretPos === 0) {
              // Reached start of line
              break
            } else {
              prevDist = newDist
              caretPos -= 1
            }
          }
        }
      } else {
        this.$emit('moveToPrevLine')
      }
    }
  }
}
</script>

<style lang="scss">
[contenteditable] {
  &:empty:after {
    content: attr(placeholder);
    opacity: 0.6;
  }
  &:focus {
    outline: none;
  }
}
.group {
  .tooltip-group {
    opacity: 0;
    left: -90px;
  }
  &:hover {
    .tooltip-group {
      opacity: 1;
    }
  }
}
.tooltip-positioning {
  left: -32px;
  width: 24px;
  height: 24px;
  &:hover {
    background: rgba(0,0,0, 0.1);
  }
  i {
    cursor: pointer;
    font-size: 24px;
    line-height: 24px;
    opacity: 0.6;
    &:hover {
      opacity: 0.8;
    }
  }
}
</style>
