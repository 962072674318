<template>
  <div class="relative group-tooltip rounded text-center">
    <slot></slot>
    <div class="tooltip-content pe-none rounded position-absolute d-none" v-html="value"></div>
  </div>
</template>

<script>
export default {
  props: ['value']
}
</script>

<style lang="scss">
.group-tooltip {
  width: 28px;
  height: 28px;
  padding: 4px;
  &:hover {
    background: rgba(0,0,0, 0.1);
  }
  i {
    cursor: pointer;
    font-size: 20px;
    line-height: 20px;
    opacity: 0.6;
    &:hover {
      opacity: 0.8;
    }
  }
}
.tooltip-content {
  &:hover {
    display: inline-block;
  }
}
</style>
