<template>
  <div class="vh-100 page-wrapper" :key="currentPage">
    <div class="page-details d-flex px-3 py-2 border-bottom justify-content-between align-items-center position-fixed">
      <div class="page-details-path">
        <p class="mb-0">
          {{ user.auth.displayName }} /&nbsp;<span contenteditable="true" @keypress.13="preventKey" @paste="updateTitle" @input="updateTitle" v-text="currentPageInfo?.title"></span></p>
      </div>
      <div class="page-details-actions">
        <span v-if="!pageUpdating" class="pe-2" :title="updatedAtDate"><small><i>{{ updatedAt }}</i></small></span>
        <span v-else class="pe-2">
          <div class="spinner-border spinner-border-sm me-1" role="status"></div>
          <span><i>Saving...</i></span>
        </span>
        <button class="btn btn-danger btn-sm" @click="deletePage"><i class="bi bi-trash"></i></button>
      </div>
    </div>
    <div class="page-content w-100">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-8 offset-md-2">
            <h1 class="big-title" contenteditable="true" @keypress.13="preventKey" @paste="updateTitle" @input="updateTitle" v-text="currentPageInfo?.title"></h1>
          </div>
          <div class="clearfix"></div>
          <div class="col-12 col-md-8 offset-md-2">
            <draggable id="blocks" tag="div" v-model="pageBlocks" v-bind="dragOptions">
              <transition-group type="transition" tag="div" class="block-group">
                <PageBlock :block="block" v-for="block, i in pageBlocks"
                  @deleteBlock="deleteBlock(i)"
                  @newBlock="insertBlock(i)"
                  @updateBlock="updateBlock"
                  @merge="merge(i)"
                  @split="split(i)"
                  @moveToNextChar="blockRefs[i+1]?.moveToStart()"
                  @moveToPrevLine="handleMoveToPrevLine(i)"
                  @moveToNextLine="blockRefs[i+1]?.moveToFirstLine(); scrollIntoView();"
                  :ref="el => blockRefs[i] = el"
                  :key="'block-'+i"
                  :numerator="i"
                  :id="block.id"
                  :type="block.type"
                  :data="block.data"/>
              </transition-group>
              <!-- <BlockComponent :block="block" v-for="block, i in currentPageInfo.blocks" :key="i" :id="'block-'+block.id"
                :blockTypes="props.blockTypes"
                :readonly="props.readonly"
                :ref="el => blockElements[i] = el"
                @deleteBlock="deleteBlock(i)"
                @newBlock="insertBlock(i)"
                @moveToPrevChar="blockElements[i-1]?.moveToEnd(); scrollIntoView();"
                @moveToNextChar="blockElements[i+1]?.moveToStart(); scrollIntoView();"
                @moveToPrevLine="handleMoveToPrevLine(i)"
                @moveToNextLine="blockElements[i+1]?.moveToFirstLine(); scrollIntoView();"
                @merge="merge(i)"
                @split="split(i)"
                @setBlockType="type => setBlockType(i, type)"
                /> -->
            </draggable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue'
import PageBlock from '@/components/PageBlock.vue'
import { BlockTypes } from '../components/utils/BlockTypes'
import { BlockType, availableBlockTypes, isTextBlock } from '@/utils/block.type'

import draggable from 'vuedraggable'

import { mapGetters } from 'vuex'
import { v4 as uuidv4 } from 'uuid'

import moment from 'moment'

import {
  SET_ACTIVE_PAGE,
  UPDATE_ACTIVE_PAGE,
  DELETE_PAGE
} from '@/store/actions.type'

const dragOptions = {
  animation: 150,
  group: 'blocks',
  disabled: false,
  ghostClass: 'block-ghost'
}

export default {
  data () {
    return {
      updateTimeout: null,
      dragOptions,
      isBlocksUpdating: false,
      currentBlocks: [],
      blockRefs: []
    }
  },
  components: {
    Sidebar,
    PageBlock,
    draggable
  },
  computed: {
    ...mapGetters(['user', 'currentPage', 'currentPageInfo', 'pageUpdating']),
    updatedAt () {
      if (this.currentPageInfo) return moment.unix(this.currentPageInfo.updatedAt.seconds).fromNow()
      else return null
    },
    updatedAtDate () {
      if (this.currentPageInfo) return moment.unix(this.currentPageInfo.updatedAt.seconds).format('DD MMMM YYYY, HH:mm:ss')
      else return null
    },
    isPageHasBlocks () {
      return this?.currentPageInfo?.blocks?.length > 0
    },
    pageBlocks: {
      get () {
        return this.currentPageInfo?.blocks
      },
      async set (value) {
        await this.$store.dispatch(UPDATE_ACTIVE_PAGE, { ...this.currentPageInfo, blocks: value })
      }
    }
  },
  created () {
    this.$store.dispatch(SET_ACTIVE_PAGE, this.$route.params.id)
  },
  mounted () {
    // this.$store.dispatch(SET_ACTIVE_PAGE, this.$route.params.id)
    // document.title = this.currentPageInfo?.title || 'Monkee – Notes without steroids'
  },
  updated () {
    this.$store.dispatch(SET_ACTIVE_PAGE, this.$route.params.id)
    document.title = this.currentPageInfo?.title || 'Monkee – Notes without steroids'
  },
  methods: {
    scrollIntoView () {
      const selection = window.getSelection()
      if (!selection || !selection.anchorNode) return
      if (selection?.anchorNode?.nodeType === Node.ELEMENT_NODE) {
        // eslint-disable-next-line no-unused-expressions
        selection?.anchorNode.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
      } else {
        // eslint-disable-next-line no-unused-expressions
        selection?.anchorNode?.parentElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
      }
    },
    setCurrentBlocks () {
      this.currentBlocks = this?.currentPageInfo?.blocks
    },
    updateCurrentBlocks (value) {
      this.currentBlocks = value
    },
    preventKey: function (event) {
      event.preventDefault()
    },
    insertBlock (index) {
      const updatedArray = this.pageBlocks
      updatedArray.splice(index + 1, 0, { id: uuidv4(), type: BlockTypes.Text, data: { text: '' } })
      this.pageBlocks = updatedArray
    },
    updateBlock (index, value) {
      const updatedArray = this.pageBlocks
      updatedArray[index].data.text = value
      this.pageBlocks = updatedArray
    },
    deleteBlock (index) {
      const updatedArray = this.pageBlocks
      if (index === 0) {
        updatedArray[0].data.text = ''
      } else {
        updatedArray.splice(index, 1)
      }
      this.pageBlocks = updatedArray
    },
    updateTitle (e) {
      clearTimeout(this.updateTimeout)
      const that = this
      this.updateTimeout = setTimeout(function () {
        that.$store.dispatch(UPDATE_ACTIVE_PAGE, {
          ...that.currentPageInfo,
          title: e.target.innerText
        })
      }, 700)
    },
    deletePage () {
      this.$store.dispatch(DELETE_PAGE)
    },
    merge (i) {
      // if (this.$props.onDeleteBlock) props.onDeleteBlock(props.page.blocks[blockIdx])
      // When deleting the first character of non-text block
      // the block should first turn into a text block
      if ([BlockType.H1, BlockType.H2, BlockType.H3, BlockType.Quote].includes(this.pageBlocks[i].type)) {
        const prevBlockContent = this.blockRefs[i].getTextContent()
        this.blockRefs[i].data.text = prevBlockContent
        setTimeout(() => {
          this.blockRefs[i].moveToStart()
        })
        return
      }
      if (i !== 0) this.mergeBlocks(i - 1, i)
    },
    mergeBlocks (prefixBlockIdx, suffixBlockIdx) {
      if (isTextBlock(this.pageBlocks[prefixBlockIdx].type)) {
        const prevBlockContentLength = this.blockRefs[prefixBlockIdx].getTextContent().length
        let suffix = this.pageBlocks[suffixBlockIdx].data.text
        if ([BlockType.H1, BlockType.H2, BlockType.H3, BlockType.Quote].includes(this.pageBlocks[suffixBlockIdx].type)) suffix = this.blockRefs[suffixBlockIdx].getTextContent()
        this.updateBlock(prefixBlockIdx, this.pageBlocks[prefixBlockIdx].data.text + suffix)
        this.deleteBlock(suffixBlockIdx)
        setTimeout(() => {
          this.blockRefs[prefixBlockIdx].setCaretPos(prevBlockContentLength)
        })
      }
      // else if ([BlockType.H1, BlockType.H2, BlockType.H3].includes(props.page.blocks[prefixBlockIdx].type)) {
      //   const prevBlockContentLength = (props.page.blocks[prefixBlockIdx] as any).details.value.length
      //   props.page.blocks[prefixBlockIdx].details.value += blockElements.value[suffixBlockIdx].getTextContent()
      //   props.page.blocks.splice(suffixBlockIdx, 1)
      //   setTimeout(() => {
      //     blockElements.value[prefixBlockIdx].setCaretPos(prevBlockContentLength)
      //   })
      // } else {
      //   if (prefixBlockIdx > 0) mergeBlocks(prefixBlockIdx - 1, suffixBlockIdx)
      //   else mergeTitle(suffixBlockIdx)
      // }
    },
    split (i) {
      const caretPos = this.blockRefs[i].getCaretPos()
      this.insertBlock(i)
      const blockTypeDetails = availableBlockTypes.find(blockType => blockType.blockType === this.pageBlocks[i].type)
      if (!blockTypeDetails) return
      if (blockTypeDetails.canSplit) {
        let htmlValue = this.blockRefs[i].getHtmlContent()
        htmlValue = htmlValue.replace('<br class="ProseMirror-trailingBreak">', '')
        this.updateBlock(i + 1, htmlValue ? htmlValue?.slice(caretPos.pos) : '')
        this.updateBlock(i, htmlValue ? htmlValue?.slice(0, caretPos.pos) : '')
      }
      setTimeout(() => this.blockRefs[i + 1].moveToStart())
    },
    handleMoveToPrevLine (blockIdx) {
      if (blockIdx !== 0) {
        // eslint-disable-next-line no-unused-expressions
        this.blockRefs[blockIdx - 1]?.moveToLastLine()
        this.scrollIntoView()
        // setTimeout(() => {
        //   // if (!title.value) return
        //   const selection = window.getSelection()
        //   const range = document.createRange()
        //   // if (title.value.childNodes.length) {
        //   //   range.setStart(title.value.childNodes[0], props.page.name.length)
        //   //   range.setEnd(title.value.childNodes[0], props.page.name.length)
        //   // } else {
        //   range.setStart(title.value, 0)
        //   range.setEnd(title.value, 0)
        //   // }
        //   selection?.removeAllRanges()
        //   selection?.addRange(range)
        // })
      }
    }
  }
}
</script>

<style lang="scss">
span:focus-visible,
.big-title:focus-visible {
  outline: none;
}
.single-block {
  + .single-block {
    margin-top: 8px;
  }
}
.page-wrapper {
  .page-details {
    background: #f6f6f6;
    width: calc(100% - 15%);
    z-index: 1000;
    top: 0;
  }
  .page-content {
    padding: 96px 0;
    height: 100vh;
    overflow: scroll;
  }
}
</style>
