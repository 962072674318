<template>
  <editor-content :editor="editor" spellcheck="false" />
</template>

<script>
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import History from '@tiptap/extension-history'
import Placeholder from '@tiptap/extension-placeholder'
import Link from '@tiptap/extension-link'
import { Editor, EditorContent } from '@tiptap/vue-2'

export default {
  data () {
    return {
      editor: null
    }
  },
  props: ['modelValue'],
  components: {
    EditorContent
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  created () {
    this.editor = new Editor({
      extensions: [
        Document,
        Paragraph,
        Text,
        Bold,
        Italic,
        History,
        Link,
        Placeholder.configure({
          placeholder: 'Start typing...'
        })
      ],
      editorProps: {
        handleDrop: () => true,
        handleDOMEvents: {
          keydown: (view, event) => {
            if (event.key === 'Enter') {
              event.preventDefault()
            }
            return false
          },
          keyup: (view, event) => {
            if (event.key === 'Enter') {
              event.preventDefault()
            }
            return false
          }
        }
      },
      content: this.modelValue,
      onUpdate: () => {
        this.mValue = this.editor.getText()
        this.$emit('updateBlock', this.mValue)
      }
    })
  },
  computed: {
    mValue: {
      get () {
        const val = this.$props.modelValue
        if (val) {
          return val
        }
        return ''
      },
      set (newValue) {
        this.$emit('update:modelValue', newValue)
      }
    }
  },
  methods: {
    setEditorValue (value) {
      this.editor.commands.setContent(value, false)
    }
  },
  watch: {
    modelValue: {
      handler: function (value) {
        const isSame = this.editor.getText() === value
        if (!isSame) {
          this.setEditorValue(value)
        }
      }
    }
  }
}
</script>

<style lang="scss">
.ProseMirror {
  * {
    cursor: text;
  }
  .is-empty {
    &.is-editor-empty {
      &:before {
        content: attr(data-placeholder);
        opacity: 0.6;
        position: absolute;
      }
    }
  }
}
</style>
